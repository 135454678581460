/* eslint-disable import/prefer-default-export */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import 'jquery/dist/jquery.min';
import 'popper.js/dist/popper';
import 'bootstrap/dist/js/bootstrap.bundle';
import './src/styles/styles.scss';

export function shouldUpdateScroll({ routerProps: { location } }) {
  const { hash, pathname } = location;

  if (pathname.match('/case-studies')) {
    const exceptForRoutes = [];
    // if the new route is part of the path above, scroll to top (0, 0)
    if (exceptForRoutes.indexOf(pathname) === -1) {
      window.scrollTo(0, 0);
    }
  }

  setTimeout(() => {
    if (pathname && hash) {
      const id = window.location.hash
        ? window.location.hash.replace(/^#/, '')
        : null;
      const element = document.getElementById(`${id}`);
      if (element !== null) {
        const offset = 80;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, 500);

  return false;
}
